import React from "react";

const Attribution = () => {
  return (
    <section className="algorithm-section">
      <div className="content">
        <h1 className="mobile-hidden">
          Patient Matching with Our Advanced API Solution
        </h1>
        <h2>
          Secure, Fast, and Intelligent Patient Comparison for Marketing
          Companies
        </h2>
        <div className="services-grid">
          <div className="service-card">
            <div className="icon">
              <i className="fas fa-lock"></i>
            </div>
            <h3>Secure Integration</h3>
            <p>
              Utilize a secure API endpoint to seamlessly compare potential
              patients with confirmed records, ensuring data integrity and
              compliance with privacy regulations.
            </p>
          </div>
          <div className="service-card mobile-hidden">
            <div className="icon">
              <i className="fas fa-bolt"></i>
            </div>
            <h3>Lightning-Fast Matching</h3>
            <p>
              Leverage custom code optimized for speed to enhance efficiency,
              providing real-time matching results that keep up with your
              operational demands.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <i className="fas fa-brain"></i>
            </div>
            <h3>Intelligent Analysis</h3>
            <p>
              Employ OpenAI's API for unmatched patients, providing extended
              functionality and insights through advanced machine learning and
              natural language processing.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <i className="fas fa-piggy-bank"></i>
            </div>
            <h3>Cost Savings Strategies</h3>
            <p>
              Reduce operational expenses by implementing a combination of
              innovative strategies that optimize resources, streamline
              workflows, and enhance overall efficiency, leading to significant
              cost savings.
            </p>
          </div>
          <div className="service-card mobile-hidden">
            <div className="icon">
              <i className="fas fa-shield-alt"></i>
            </div>
            <h3>Advanced Data Protection</h3>
            <p>
              Implement robust security protocols to protect sensitive patient
              information, including encryption and secure authentication
              mechanisms.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <i className="fas fa-chart-line"></i>
            </div>
            <h3>Comprehensive Analytics</h3>
            <p>
              Gain valuable insights with detailed analytics and reporting
              features, helping you make data-driven decisions to optimize your
              marketing strategies.
            </p>
          </div>
        </div>
        <br />
        <br />
        <a className="contact-btn cta-button" href="/contact">
          Unlock the Power of Efficient Patient Matching Today!
        </a>
      </div>
    </section>
  );
};

export default Attribution;
