import React from "react";
import CodeEffect from "./CodeEffect";

const AINotifications = () => {
  return (
    <section className="algorithm-section">
      <div className="content">
        <h1 className="mobile-hidden">
          AI Smart Notifications for Real-Time Business Insights
        </h1>
        <h2>
          Stay Ahead with Intelligent, Data-Driven Notifications for Marketing
          Companies
        </h2>
        <div className="services-grid">
          <div className="service-card">
            <div className="icon">
              <i className="fas fa-chart-line"></i>
            </div>
            <h3>Automated Trend Detection</h3>
            <p>
              Receive instant alerts as your key metrics rise or fall, enabling
              timely decisions and proactive adjustments to your marketing
              strategies.
            </p>
          </div>
          <div className="service-card mobile-hidden">
            <div className="icon">
              <i className="fas fa-bell"></i>
            </div>
            <h3>Color-Coded Notifications</h3>
            <p>
              Visualize performance at a glance with color-coded alerts that
              align with set tolerances, providing clear insights into data
              movements.
            </p>
          </div>
          <div className="service-card">
            <CodeEffect arr={["Stay Ahead", "Get Alerts", "Make Decisions"]} />
          </div>
        </div>
        <br />
        <p>
          With our AI Smart Notifications, you’re empowered to stay on top of
          critical metrics, minimize risks, and capitalize on opportunities as
          they arise. Get personalized alerts that drive efficiency and boost
          your decision-making precision.
        </p>
        <p className="mobile-hidden">
          <br />
          Designed to be API-enabled, our solution integrates effortlessly into
          your existing workflows, ensuring you receive real-time alerts without
          needing to overhaul your current systems. From tracking campaign
          effectiveness to monitoring customer engagement levels, our AI-driven
          notifications give you an edge, delivering data insights when you need
          them most.
        </p>
        <br />
        <a className="contact-btn cta-button" href="/contact">
          Request a Demo
        </a>
      </div>
    </section>
  );
};

export default AINotifications;
