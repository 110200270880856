import React from "react";
import { Element } from "react-scroll";
import SEO from "./SEO";
import "../App.css";
import Slideshow from "./Slideshow";
import Testimonials from "./Testimonials";

import img1 from "./slide5.webp";
import img2 from "./slide4.webp";
import img3 from "./slide3.webp";

import profileImg from "./me.jpeg";

import {
  FaSalesforce,
  FaCode,
  FaRobot,
  FaProjectDiagram,
  FaChartLine,
  FaCogs,
} from "react-icons/fa"; // Import some icons
import Attribution from "./Attribution";
import Menu from "./Menu";
import Footer from "./Footer";
import AINotifications from "./AINotifications";
import AIEasyImplementation from "./AIEasyImplementation";

function Home() {
  return (
    <>
      <SEO />
      <Menu />
      {/* Add a container for scroll snapping */}
      <div className="container">
        <Element
          id="home"
          name="home"
          className="section home"
          style={{ padding: 0 }}
        >
          <Slideshow>
            <div className="slide" key={1}>
              <img
                src={img1}
                alt="Empowering Startups with Innovative Technology"
              />
              <div className="text-overlay">
                <h1>Empowering Startups with Innovative AI Technology</h1>
                <p>
                  We specialize in implementing advanced <b>AI algorithms</b>{" "}
                  designed for easy integration, helping startups and businesses
                  of all sizes achieve efficiency and growth. Our solutions come
                  with pre-built, optimized prompts that simplify AI adoption,
                  allowing you to leverage automation, predictive insights, and
                  data-driven decision-making without heavy technical overhead.
                </p>
                <p>
                  Whether you're optimizing processes or managing budgets, our
                  AI tools are built for scalability, adaptability, and
                  cost-effectiveness, ensuring seamless integration into your
                  existing operations while keeping expenses manageable.
                </p>

                <br />
                <a
                  className="contact-btn"
                  style={{ marginRight: 10 }}
                  href="/contact"
                >
                  Get More Info
                </a>
                <a
                  className="contact-btn"
                  href="https://www.linkedin.com/in/renzo-dupont-b9797941/details/recommendations/"
                >
                  LinkedIn
                </a>
              </div>
            </div>

            <div className="slide" key={2}>
              <img src={img2} alt="Certified Salesforce Expert" />
              <div className="text-overlay">
                <h1>
                  Certified Salesforce Expertise to Maximize Your CRM Potential
                </h1>
                <p>
                  Unlock the full power of your Customer Relationship Management
                  with our certified Salesforce experts. We tailor CRM solutions
                  to streamline your processes, enhance customer engagement, and
                  drive business growth.
                </p>
                <a
                  className="contact-btn"
                  href="https://www.linkedin.com/in/renzo-dupont-b9797941/details/recommendations/"
                  style={{ marginRight: 10 }}
                >
                  LinkedIn
                </a>
                <a className="contact-btn" href="/about">
                  About Me
                </a>
              </div>
            </div>

            <div className="slide" key={3}>
              <img src={img3} alt="Full-stack Development Services" />
              <div className="text-overlay">
                <h1>
                  Comprehensive Full-stack Development Tailored to Your Business
                  Needs
                </h1>
                <p>
                  Our team offers full-stack development services that provide
                  custom solutions for your unique business challenges. From
                  front-end to back-end, we build robust, scalable applications
                  that propel your business forward.
                </p>
                <a
                  className="contact-btn"
                  style={{ marginRight: 10 }}
                  href="/contact"
                >
                  Get In Touch!
                </a>
                <a className="contact-btn" href="/about">
                  About Me
                </a>
              </div>
            </div>
          </Slideshow>
        </Element>
        <Element id="about" name="about" className="section about">
          <div className="profile-container">
            {/* Profile Section */}
            <div className="profile-section">
              <div className="profile-card">
                <img src={profileImg} alt="Renzo" className="profile-img" />
                <div className="profile-info">
                  <h2>Senior Computer Science Engineer</h2>
                  <p>
                    AI Implementation Expert & Senior Computer Science Engineer
                    with 16+ years of experience in software development,
                    project management, and IT leadership. I specialize in
                    Salesforce, ERP systems, and full-stack development, with a
                    proven track record of leading cross-functional teams and
                    delivering scalable technology solutions for startups and
                    enterprises.
                  </p>
                  <p>Fluent in English and a native Spanish speaker.</p>
                  <p className="mobile-hidden">
                    Proficient in Microsoft .NET, Salesforce, OpenAI, Node.js,
                    React, and more.
                  </p>

                  <a className="contact-btn mobile-hidden" href="/about">
                    Want to know more?
                  </a>
                </div>
              </div>
            </div>

            {/* Experience Section */}
            <Testimonials />
          </div>
        </Element>
        <Element
          id="ai-notification"
          name="ai-notification"
          className="section ai-notification"
        >
          <AINotifications />
        </Element>
        <Element id="services" name="services" className="section services">
          <div className="services-container">
            <h2 className="services-title">My Services</h2>
            <div className="services-grid">
              <div className="service-card">
                <FaRobot className="service-icon" />
                <h3>AI Implementation & Optimization</h3>
                <p>
                  Seamlessly integrate cutting-edge AI tools to automate
                  workflows, enhance analytics, and drive smarter
                  decision-making. Leveraging the latest trends in AI, including
                  machine learning and natural language processing, we provide
                  pre-built, budget-friendly solutions that accelerate your
                  digital transformation without the usual headaches.
                </p>
              </div>
              <div className="service-card">
                <FaSalesforce className="service-icon" />
                <h3>Salesforce AI Integration</h3>
                <p>
                  Elevate your Salesforce CRM with custom AI integrations like
                  Einstein Analytics to optimize business processes, improve
                  customer engagement, and streamline operations. Stay ahead
                  with predictive insights and personalized experiences directly
                  within your CRM platform.
                </p>
              </div>
              <div className="service-card">
                <FaProjectDiagram className="service-icon" />
                <h3>Advanced Algorithm Design</h3>
                <p>
                  Develop tailored algorithms using the latest AI and machine
                  learning techniques to optimize your business workflows, boost
                  efficiency, and enhance decision-making. Our solutions are
                  scalable and adaptable, fitting your unique needs while
                  keeping within your budget.
                </p>
              </div>
              <div className="service-card">
                <FaCogs className="service-icon" />
                <h3>System Management & AI-Driven Efficiency</h3>
                <p>
                  Implement and manage systems that improve operational
                  productivity through AI-driven tools like robotic process
                  automation (RPA) and intelligent automation. Streamline
                  processes, reduce manual effort, and cut unnecessary costs
                  with the latest technology.
                </p>
              </div>
              <div className="service-card mobile-hidden">
                <FaChartLine className="service-icon" />
                <h3>AI-Powered Business Intelligence</h3>
                <p>
                  Unleash the power of your data with AI-powered insights using
                  advanced analytics and data visualization tools. Gain clear,
                  actionable recommendations to guide your business forward,
                  leveraging big data and predictive analytics to stay
                  competitive.
                </p>
              </div>
              <div className="service-card mobile-hidden">
                <FaCode className="service-icon" />
                <h3>Custom AI Development</h3>
                <p>
                  Whether starting from scratch or enhancing existing systems,
                  we deliver AI-powered software solutions customized to your
                  specific business needs. Focused on budget management and ease
                  of use, we employ the latest in AI development trends to
                  ensure scalability and performance.
                </p>
              </div>
            </div>
          </div>
        </Element>
        <Element
          id="experience"
          name="experience"
          className="section experience"
        >
          <div className="experience-container">
            <h2 className="experience-title">Professional Experience</h2>
            <div className="timeline">
              {/* Timeline Item */}
              <div className="timeline-item">
                <div className="timeline-content">
                  <span className="timeline-date">2024 - Present</span>
                  <h3>Sr. Computer Science Engineer</h3>
                  <h4>Gargle Inc. (USA)</h4>
                  <p>
                    As Sr. Computer Science Engineer, my responsibility is to
                    plan, design and develop solutions for Gargle users and
                    clients. My main focus is to contribute in all the necessary
                    aspects to make sure daily operations among departments work
                    as smoothly as possible, as well as integrate external
                    systems to automate processes and optimize delivery for new
                    solutions.{" "}
                    <span className="mobile-hidden">
                      In addition to this, I try to contribute as much as
                      possible to provide the best possible support to our
                      users.
                    </span>
                  </p>
                </div>
              </div>

              {/* Timeline Item */}
              <div className="timeline-item">
                <div className="timeline-content">
                  <span className="timeline-date">2019 - 2024</span>
                  <h3>Contractor Chief Technology Officer</h3>
                  <h4>DentalMarketing.net (USA)</h4>
                  <p>
                    As a Contractor CTO, I manage the engineering team and
                    contribute directly in the decision-making process for IT
                    solutions, analyzing the short and long-term needs of the
                    organization to help towards reaching its goals.{" "}
                    <span className="mobile-hidden">
                      I focused on building the company's strategy to develop
                      web applications, platform integration, and internal
                      operation platforms using the latest technologies having
                      in mind the cost-benefit factor.
                    </span>
                  </p>
                </div>
              </div>

              {/* Timeline Item */}
              <div className="timeline-item">
                <div className="timeline-content">
                  <span className="timeline-date">2009 - 2019</span>
                  <h3>IT Project Manager (International)</h3>
                  <h4>Credilit S.A.</h4>
                  <p>
                    <span className="mobile-hidden">
                      Credilit S.A. is a credit administrator with more than 40
                      years of experience working in several states in Uruguay.
                    </span>{" "}
                    As IT Project Manager, my responsibility is to manage
                    projects for all branches and departments of the company,
                    such as sales, accounting, marketing, etc, using
                    technologies such as Salesforce, ASP .NET, HTML, CSS, and
                    Javascript.
                  </p>
                </div>
              </div>

              {/* Timeline Item */}
              <div className="timeline-item">
                <div className="timeline-content">
                  <span className="timeline-date">2009 - 2019</span>
                  <h3>Team Lead (International)</h3>
                  <h4>Altimetrik</h4>
                  <p>
                    Led the Salesforce GEMINI Project at Altimetrik as Team
                    Lead, overseeing all phases from planning to deployment and
                    ensuring timely, quality delivery. Assisted in key projects
                    like Campaign to Lead through code reviews. Mentored junior
                    developers and implemented Agile methodologies to boost team
                    efficiency and product quality.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Element>
        <Element
          id="ai-easy-implementation"
          name="ai-easy-implementation"
          className="section ai-easy-implementation"
        >
          <AIEasyImplementation />
        </Element>
        <Element
          id="attribution"
          name="attribution"
          className="section attribution"
        >
          <Attribution />
        </Element>
        <Element id="contact" name="contact" className="section contact">
          <div className="contact-container">
            <h2 className="contact-title">Get in Touch</h2>
            <p className="contact-description">
              I'm excited to discuss how we can collaborate. Please fill out the
              form below or reach out directly via email.
            </p>

            {/* Contact Form */}
            <form
              action="https://formspree.io/f/xnnaykzd" // Replace with your own Formspree form ID
              method="POST"
              className="contact-form"
            >
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="6"
                  placeholder="Your Message"
                  required
                ></textarea>
              </div>

              <button type="submit" className="contact-btn">
                Send Message
              </button>

              <br />
              <br />
              <Footer />
            </form>
          </div>
        </Element>
      </div>
    </>
  );
}

export default Home;
