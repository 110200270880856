import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import logo from "./logo.png";

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);

  // Toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSubMenuToggle = (item) => {
    if (item === 1) setIsSubMenuOpen((prevState) => !prevState);
    if (item === 2) setIsSubMenuOpen2((prevState) => !prevState);
  };

  return (
    <div className="navbar">
      {/* Hamburger Menu for Mobile */}
      <div className="menu-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      {/* Navbar Links */}
      <div className={`nav-links ${isMenuOpen ? "nav-links-mobile" : ""}`}>
        <img src={logo} alt="Startupp AI Logo" className="logo" />
        <HashLink
          smooth
          to="/#home"
          onClick={isMenuOpen ? toggleMenu : undefined}
        >
          Home
        </HashLink>
        <HashLink
          smooth
          to="/#about"
          onClick={isMenuOpen ? toggleMenu : undefined}
        >
          About
        </HashLink>
        <HashLink
          smooth
          to="/#services"
          onClick={isMenuOpen ? toggleMenu : undefined}
        >
          Services
        </HashLink>
        <HashLink
          smooth
          to="/#experience"
          onClick={isMenuOpen ? toggleMenu : undefined}
        >
          Experience
        </HashLink>
        <HashLink
          smooth
          to="/#contact"
          onClick={isMenuOpen ? toggleMenu : undefined}
        >
          Contact
        </HashLink>
        {/* Menu Item with Submenu */}
        <div
          className="menu-item"
          onMouseEnter={() => setIsSubMenuOpen(true)}
          onMouseLeave={() => setIsSubMenuOpen(false)}
          onClick={() => handleSubMenuToggle(1)} // For mobile devices
        >
          <span>About Me</span>
          <div className={`submenu ${isSubMenuOpen ? "submenu-open1" : ""}`}>
            <HashLink
              smooth
              to="https://www.linkedin.com/in/renzo-dupont-b9797941/details/recommendations/"
              onClick={isMenuOpen ? toggleMenu : undefined}
              target="_blank"
            >
              Recommendations
            </HashLink>
            <HashLink
              smooth
              to="/contact"
              onClick={isMenuOpen ? toggleMenu : undefined}
            >
              Get in Touch
            </HashLink>
            <separator></separator>
            <HashLink
              smooth
              to="/about"
              onClick={isMenuOpen ? toggleMenu : undefined}
            >
              About Me
            </HashLink>
          </div>
        </div>
        <div
          className="menu-item"
          onMouseEnter={() => setIsSubMenuOpen2(true)}
          onMouseLeave={() => setIsSubMenuOpen2(false)}
          onClick={() => handleSubMenuToggle(2)} // For mobile devices
        >
          <span>Resources</span>
          <div className={`submenu ${isSubMenuOpen2 ? "submenu-open2" : ""}`}>
            <HashLink
              smooth
              to="https://www.greenfenix.com.uy"
              onClick={isMenuOpen ? toggleMenu : undefined}
              target="_blank"
            >
              Latin America &amp; Europe
            </HashLink>
            <HashLink
              smooth
              to="/blog/maximizing-daily-productivity-with-chatgpt"
              onClick={isMenuOpen ? toggleMenu : undefined}
            >
              Maximizing Daily Productivity with ChatGPT
            </HashLink>
            <HashLink
              smooth
              to="/blog/top-5-prompts-for-managers"
              onClick={isMenuOpen ? toggleMenu : undefined}
            >
              Top 5 Prompts for Managers
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
