import React from "react";
import { Helmet } from "react-helmet";

function SEO() {
  return (
    <Helmet>
      <title>
        Startupp.ai | Making advanced technology accessible and impactful for
        every business
      </title>
      <meta
        name="description"
        content="Expert IT consultant offering Salesforce, ERP, and software development solutions."
      />
      <meta
        name="keywords"
        content="IT Consultant, Salesforce Developer, ERP Specialist, Full-stack Developer, Software Development, AI Integration, Startups Consulting, Business Intelligence, Digital Marketing Consulting"
      />
      <meta name="author" content="Renzo" />
    </Helmet>
  );
}

export default SEO;
