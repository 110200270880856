import React from "react";

const AIEasyImplementation = () => {
  return (
    <section className="algorithm-section">
      <div className="content">
        <h1 className="mobile-hidden">
          Accelerate Your AI Journey with Quick & Smart Implementation
        </h1>
        <h2>
          Flexible, Cost-Efficient, and Scalable AI Solutions for Startups and
          Fast-Paced Businesses
        </h2>
        <div className="services-grid">
          <div className="service-card">
            <div className="icon">
              <i className="fas fa-rocket"></i>
            </div>
            <h3>Rapid API Integration</h3>
            <p>
              Get up and running quickly with our cost-effective API algorithms.
              Our solutions seamlessly connect with your existing data, saving
              you time and lowering setup expenses.
            </p>
          </div>
          <div className="service-card mobile-hidden">
            <div className="icon">
              <i className="fas fa-wrench"></i>
            </div>
            <h3>Customizable AI Models</h3>
            <p>
              Tailor our algorithms to your specific needs without the heavy
              expense of developing a custom solution. Our flexible models
              ensure you pay only for the functionality you truly need.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <i className="fas fa-lightbulb"></i>
            </div>
            <h3>Expert Cost Management</h3>
            <p>
              Avoid unnecessary spending with AI strategies optimized for your
              budget. Our expertise ensures efficient implementation that
              maximizes value while minimizing costs.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <i className="fas fa-piggy-bank"></i>
            </div>
            <h3>Resource Optimization</h3>
            <p>
              Leverage AI to streamline workflows and reduce operational costs.
              Our AI tools are built for startups that need to maintain high
              performance while controlling expenditures.
            </p>
          </div>
          <div className="service-card mobile-hidden">
            <div className="icon">
              <i className="fas fa-shield-alt"></i>
            </div>
            <h3>Built-In Security</h3>
            <p>
              Integrate AI with confidence, knowing that built-in security
              protocols protect your data without requiring additional costly
              solutions.
            </p>
          </div>
          <div className="service-card mobile-hidden">
            <div className="icon">
              <i className="fas fa-tachometer-alt"></i>
            </div>
            <h3>Scalable Performance</h3>
            <p>
              Our AI solutions are scalable and grow with your company, allowing
              you to expand without the burden of costly upgrades, keeping
              long-term costs in check.
            </p>
          </div>
        </div>
        <br />
        <p>
          With AI Quick & Smart Implementation, any business—especially startups
          with limited resources—can harness AI to drive growth while
          effectively managing costs. Designed to be budget-friendly, our
          API-enabled algorithms and flexible models ensure you get the maximum
          return on your investment.
        </p>
        <br />
        <a className="contact-btn cta-button" href="/contact">
          Start Your AI Transformation Today!
        </a>
      </div>
    </section>
  );
};

export default AIEasyImplementation;
