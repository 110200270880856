import React from "react";
import SEO from "./SEO";
import "../App.css";
import Menu from "./Menu";
import { motion } from "framer-motion";
import profileImg from "./me.jpeg";
import Footer from "./Footer";

function About() {
  const sectionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: (i = 1) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.3,
        duration: 0.6,
      },
    }),
  };

  return (
    <>
      <SEO />
      <Menu />
      <div className="about-me-container">
        <motion.img
          src={profileImg}
          alt="Profile"
          className="profile-image"
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={1}
        />

        <motion.h1
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={2}
        >
          About Me
        </motion.h1>

        <motion.h2
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={3}
        >
          AI Implementation Expert & Senior Computer Science Engineer
        </motion.h2>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={4}
        >
          Welcome! I'm an AI Implementation Expert and Senior Computer Science
          Engineer with over 16 years of experience in software development,
          project management, and IT leadership. My passion lies in leveraging
          artificial intelligence and automation to transform businesses and
          drive innovation.
        </motion.p>

        {/* Journey of Innovation */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={5}
        >
          A Journey of Innovation
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={6}
        >
          My career has been dedicated to exploring the cutting edge of
          technology. Early on, I recognized the transformative power of AI and
          have since specialized in implementing advanced algorithms that
          automate processes, enhance analytics, and improve decision-making. My
          work spans startups to enterprises, always focusing on delivering
          scalable and adaptable solutions.
        </motion.p>

        {/* Milestone: Patient Attribution Algorithm */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={7}
        >
          Pioneering AI Solutions
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={8}
        >
          In 2020, I initiated the development of a Patient Attribution
          algorithm that revolutionized how healthcare providers understand and
          engage with their patients. By integrating OpenAI's API and crafting
          custom logic, I enhanced the algorithm's accuracy and efficiency. This
          innovation not only improved patient care but also streamlined
          operations, setting a new standard in healthcare analytics.
        </motion.p>

        {/* Milestone: Custom Address Comparison Algorithm */}
        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={9}
        >
          Recognizing the need for cost-effective solutions, I developed a
          custom address comparison algorithm that operates without relying on
          external endpoints. This approach significantly reduced operational
          costs while maintaining high accuracy in data matching and validation.
          By eliminating dependencies on third-party services, businesses could
          achieve greater autonomy and control over their processes.
        </motion.p>

        {/* Professional Expertise */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={10}
        >
          Professional Expertise
        </motion.h3>

        <motion.ul
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={11}
        >
          <li>
            <strong>AI Implementation & Optimization:</strong> I specialize in
            integrating AI tools seamlessly into existing operations, making
            advanced technology accessible and cost-effective. By providing
            pre-built, optimized prompts, I help businesses adopt AI without the
            usual complexities.
          </li>
          <li>
            <strong>Custom Algorithm Development:</strong> My work includes
            creating bespoke algorithms like the Patient Attribution system and
            address comparison tools, tailored to meet specific business needs
            and challenges.
          </li>
          <li>
            <strong>Salesforce & ERP Systems:</strong> With extensive experience
            in Salesforce development, including custom integrations and
            enhancements like Einstein Analytics, I optimize CRM platforms to
            improve customer engagement and streamline operations.
          </li>
          <li>
            <strong>Full-Stack Development:</strong> Proficient in Microsoft
            .NET, Node.js, React, and more, I craft end-to-end solutions that
            meet unique business needs. My full-stack expertise ensures that
            every layer of the application works harmoniously.
          </li>
        </motion.ul>

        {/* Leadership & Collaboration */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={12}
        >
          Leadership & Collaboration
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={13}
        >
          Throughout my career, I've not only been a hands-on developer but also
          a supportive leader and manager. At Altimetrik, I served as Team Lead
          and Senior Salesforce Developer, where I led the Salesforce GEMINI
          Project from conception to deployment. My role involved:
        </motion.p>

        <motion.ul
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={14}
        >
          <li>
            <strong>Project Management:</strong> Overseeing all phases of
            development, ensuring timely delivery and high-quality results.
          </li>
          <li>
            <strong>Team Leadership:</strong> Mentoring junior developers and
            fostering a collaborative environment that emphasizes continuous
            learning.
          </li>
          <li>
            <strong>Cross-Project Collaboration:</strong> Assisting in key
            projects like Campaign to Lead and Lead to Opportunity through code
            reviews and integrating solutions across platforms.
          </li>
        </motion.ul>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={15}
        >
          Implementing Agile methodologies, I streamlined development processes,
          boosted team efficiency, and enhanced product quality. My managerial
          background extends to supporting small to medium-sized teams as a
          technical resource, guiding them through complex challenges and
          enabling them to achieve their goals.
        </motion.p>

        {/* Global Perspective */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={16}
        >
          Global Perspective
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={17}
        >
          Being fluent in English and a native Spanish speaker allows me to
          navigate multicultural environments effortlessly. This linguistic
          proficiency enhances communication with diverse teams and clients,
          fostering strong relationships and successful collaborations across
          the globe.
        </motion.p>

        {/* Client-Centered Approach */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={18}
        >
          Client-Centered Approach
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={19}
        >
          I believe in a personalized approach to technology solutions.
          Understanding that every business is unique, I focus on:
        </motion.p>

        <motion.ul
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={20}
        >
          <li>
            <strong>Budget Management:</strong> Providing cost-effective
            solutions without compromising on quality, ensuring that even
            businesses with limited resources can benefit from advanced
            technology.
          </li>
          <li>
            <strong>Pre-Built Solutions:</strong> Offering ready-to-implement AI
            prompts and tools to accelerate adoption and reduce implementation
            time.
          </li>
          <li>
            <strong>Professionalism:</strong> Ensuring transparency,
            reliability, and excellence in every project, building trust and
            long-term partnerships with clients.
          </li>
          <li>
            <strong>Technical Support:</strong> Acting as a technical resource
            for teams, providing guidance and expertise to help them overcome
            challenges and succeed.
          </li>
        </motion.ul>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={21}
        >
          Your success is my priority. I strive to be an approachable partner
          who genuinely cares about helping your business thrive. By tailoring
          solutions to your specific needs, I aim to make advanced technology
          accessible and beneficial for you.
        </motion.p>

        {/* Let's Collaborate */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={22}
        >
          Let's Collaborate
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={23}
        >
          The future is bright with possibilities in AI and automation. Whether
          you're looking to optimize existing processes, reduce operational
          costs, or embark on innovative projects, I'm here to help you navigate
          the technological landscape. My expertise in developing custom
          algorithms and managing teams ensures that you receive comprehensive
          support throughout your journey.
        </motion.p>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={24}
        >
          Feel free to reach out, and let's explore how we can turn your vision
          into reality. Together, we can harness the power of technology to
          drive growth and success.
        </motion.p>
      </div>
      <div className="contact-container">
        <h2 className="contact-title">Get in Touch</h2>
        <p className="contact-description">
          I'm excited to discuss how we can collaborate. Please fill out the
          form below or reach out directly via email.
        </p>

        {/* Contact Form */}
        <form
          action="https://formspree.io/f/xnnaykzd" // Replace with your own Formspree form ID
          method="POST"
          className="contact-form"
        >
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              rows="6"
              placeholder="Your Message"
              required
            ></textarea>
          </div>

          <button type="submit" className="contact-btn">
            Send Message
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default About;
